import * as React from "react"
import { Head } from "../../components/head"
import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ExpansionPanel } from "../../components/_packages/expansionPanel"
import { ExpandedPanel } from "../../components/_packages/expandedPanel"
import { FullScreenPanel } from "../../components/_packages/fullScreenPanel"
import { social } from "../../content/packageContent"
import BackgroundImage from "gatsby-background-image"
import {
  RelatedPackages,
  Package,
} from "../../components/_packages/relatedPackages"
import { SectionContainer } from "../../components/shared/containers"
import { Header, HeaderCenter } from "../../components/shared/type"

const WebDesignAndDevelopmentPage = (): JSX.Element => {
  const { socialImg } = useStaticQuery(
    graphql`
      query {
        socialImg: file(relativePath: { eq: "services/socialMedia.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const socialFluid = socialImg.childImageSharp.fluid

  return (
    <>
      <SEO title="Social Media & Content Creation" />
      <Head title={"Social Media & Content Creation"} />
      <Layout>
        <SectionContainer  style={{paddingBottom: "2rem"}}>
          <PackageHeader>
            <FluidImg
              fluid={socialFluid}
              style={{ backgroundSize: "contain" }}
            />
            <div>
              <Header>
                Social Media &<br /> Content Creation
              </Header>
              <Description>{social.description}</Description>
            </div>
          </PackageHeader>
          </SectionContainer>

          <SectionTitle>Social Media Campaigns</SectionTitle>
          <PanelsSmall>
            <ExpansionPanel
              plan={social.plans.basic}
              // expanded={true}
              package="Social Media & Content Creation"
            />
            <ExpansionPanel
              plan={social.plans.mid}
              package="Social Media & Content Creation"
            />
            <ExpansionPanel
              plan={social.plans.top}
              package="Social Media & Content Creation"
            />
          </PanelsSmall>
          <SectionTitle>Social Media Content & Consulting</SectionTitle>
          <PanelsSmall>
            <ExpansionPanel
              plan={social.plans.engagement}
              package="Social Media Content & Consulting"
            />
            <ExpansionPanel
              plan={social.plans.shoot}
              package="Social Media Content & Consulting"
            />
            <ExpansionPanel
              plan={social.plans.consulting}
              package="Social Media Content & Consulting"
            />
          </PanelsSmall>
          <FullScreenPanel title="Social Media Campaigns" 
          // expanded={true}
          >
            <ExpandedPanel
              plan={social.plans.basic}
              package="Social Media & Content Creation"
            />
            <ExpandedPanel
              plan={social.plans.mid}
              package="Social Media & Content Creation"
            />
            <ExpandedPanel
              plan={social.plans.top}
              package="Social Media & Content Creation"
            />
          </FullScreenPanel>
          <FullScreenPanel title="Social Media Content & Consulting">
            <ExpandedPanel
              plan={social.plans.engagement}
              package="Social Media Content & Consulting"
            />
            <ExpandedPanel
              plan={social.plans.shoot}
              package="Social Media Content & Consulting"
            />
            <ExpandedPanel
              plan={social.plans.consulting}
              package="Social Media Content & Consulting"
            />
          </FullScreenPanel>
          <RelatedPackages currentPackage={Package.SOCIAL} />
      </Layout>
    </>
  )
}

const Description = styled.p`
  @media (min-width: 768px) {
  }
`

const FluidImg = styled(BackgroundImage)`
  height: 200px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    height: 300px;
    width: 400px;
    justify-self: end;
  }
`

const PackageHeader = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column-gap: 75px;
  }
`

const PanelsSmall = styled.div`
  :nth-of-type(3) {
    background-color: var(--grey);
  }

  :nth-of-type(3) > div {
    background-color: var(--grey);
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const SectionTitle = styled(HeaderCenter)`
  color: var(--brand-green);
  padding: 1.5rem 0;
  background-color: var(--grey);

  @media (min-width: 768px) {
    display: none;
  }
`

export default WebDesignAndDevelopmentPage
